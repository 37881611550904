
import Videos from "@/components/jobfair/Videos"
export default {
    name: "RegionSeoContent",
    components: {
        Videos,
        LandshutSeoContent: () =>
            import(`@/components/region/landshut/RegionSeoContent`),
        HauzenbergSeoContent: () =>
            import(`@/components/region/hauzenberg/RegionSeoContent`),
        AltmuehlfrankenSeoContent: () =>
            import(`@/components/region/altmuehlfranken/RegionSeoContent`),
        InnsalzachSeoContent: () =>
            Promise.resolve({ render: (h) => h("div") }), // NO seo content
        PassauSeoContent: () => Promise.resolve({ render: (h) => h("div") }), // NO seo content
    },
    props: {
        /** region to display SEO content for */
        region: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        customSeoSlugs: [
            "hauzenberg",
            "landshut",
            "altmuehlfranken",
            "inn-salzach",
            "passau",
        ],
    }),
    computed: {
        regionSlug() {
            return this.region.slug
        },
        hasCustomSeoContent() {
            return this.customSeoSlugs.includes(this.regionSlug)
        },
        showSeoContent() {
            return this.seoContentText || this.showSeoVideos
        },
        regionName() {
            return this.region.name
        },
        seoContentText() {
            return this.region.about_us
        },
        componentName() {
            let regionSlug = this.regionSlug.replace(/-/g, "")
            const name =
                regionSlug.charAt(0).toUpperCase() + regionSlug.slice(1)
            return name + "SeoContent"
        },
        showSeoVideos() {
            return this.displayedSeoVideos.length > 0
        },
        displayedSeoVideos() {
            return this.region.channel &&
                Array.isArray(this.region.channel.videos)
                ? this.region.channel.videos.filter(
                      ({ type }) => type === "seo"
                  )
                : []
        },
    },
}
