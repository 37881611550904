
import RegionSeoContent from "@/components/region/RegionSeoContent"
import RegionToolbar from "@/components/layout/navigation/RegionToolbar"
import RegionHeader from "@/components/region/RegionHeader"
import SearchResultList from "@/components/search/SearchResultList"
import ViewAllButton from "@/components/base/button/ViewAllButton"
import Videos from "@/components/jobfair/Videos"

import regionMeta from "@/mixins/regionMeta"
import currentYearMixin from "@/mixins/currentYearMixin"
import regionSearchRouting from "@/models/regionSearchRouting"
import { mdiChevronRight } from "@mdi/js"
import postService from "@/services/post.service.js"
import { mapGetters } from "vuex"

export default {
    meta: {
        searchPage: "true",
        searchCategory: "Alles",
        toolbar: {
            hideSearch: true,
        },
        footer: {
            showPowered: true,
            showForBusinesses: true,
            directRegistration: true,
        },
    },
    components: {
        Videos,
        RegionToolbar,
        RegionSeoContent,
        RegionHeader,
        ViewAllButton,
        SearchResultList,
        Livestream: () => import("@/components/livestream/Livestream"),
    },
    layout(context) {
        return context.$auth.user && context.$auth.user.region
            ? "modern"
            : "default"
    },
    mixins: [currentYearMixin, regionMeta],
    watchQuery: true,
    async asyncData({ store, route, $axios, redirect }) {
        let categories = regionSearchRouting.getRouting(route)
        const regionPosts = await postService
            .getPosts({ region: "altmuehlfranken" })
            .catch((err) => {
                console.warn(err)
            })

        const response = await $axios
            .$get("/api/regions/" + "altmuehlfranken")
            .catch((err) => {
                console.warn(err)
                redirect("/")
            })
        if (!response) return
        const region = response.data
        await store.dispatch("search/init", {
            categories: categories,
            selectedCategory: "Alles",
            locationSlug: "altmuehlfranken",
            query: route.query,
            channel: region.channel.slug,
            type: "region",
        })
        return {
            region,
            regionPosts,
        }
    },
    data: function () {
        return {
            mdiChevronRight,
            region: {},
            anyResults: true,
            regionPosts: [],
        }
    },
    computed: {
        ...mapGetters("search", ["route", "searchID", "search"]),
        ...mapGetters("livestream", ["inWindow"]),
        searchResult() {
            return this.search(this.searchID("Unternehmen"))?.data
        },
        metaTitle() {
            return `Meine Zukunft ${this.region.name}: Ausbildung, Praktika und Betriebe`
        },
        metaDescription() {
            return `Entdecke freie Ausbildungsplätze, Schülerpraktika und die besten Firmen in der Region ${this.region.name}. Starte deine Zukunft und finde deinen Traumberuf.`
        },
        showApprenticeships() {
            return true
        },
        isAltmuehlfranken() {
            return true
        },
        /* Return the earliest 'is_live' livestream or the most recent upcoming livestream */
        livestream() {
            if (!this.region.livestreams) return null
            const upcoming = this.region.livestreams.filter(
                ({ planned_end, is_live }) =>
                    new Date(planned_end) < new Date() || is_live
            )
            const sorted = upcoming.sort(
                (a, b) => new Date(a.planned_start) - new Date(b.planned_start)
            )
            if (sorted.length === 0) return null
            return sorted.find(({ is_live }) => is_live) || sorted[0]
        },
        showHeaderVideos() {
            return this.displayedHeaderVideos.length > 0
        },
        displayedHeaderVideos() {
            return !!this.region.channel &&
                Array.isArray(this.region.channel.videos)
                ? this.region.channel.videos.filter(
                      ({ type }) => type === "header"
                  )
                : []
        },
    },
    head() {
        return {
            title: this.metaTitle,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: this.metaDescription,
                },
                {
                    hid: "og:title",
                    name: "og:title",
                    content: this.metaTitle,
                },
                {
                    hid: "og:description",
                    name: "og:description",
                    content: this.metaDescription,
                },
                ...this.getOgImage(),
            ],
        }
    },
}
