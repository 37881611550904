
import { mdiYoutube } from "@mdi/js"

import vueVimeoPlayer from "vue-vimeo-player"
import Vue from "vue"
Vue.use(vueVimeoPlayer)

export default {
    name: "Videos",
    props: {
        /** array of videos to display */
        videos: {
            type: Array,
            required: true,
        },
        /** type of the component [header, seo] */
        type: {
            type: String,
            default: "header",
        },
    },
    data: function () {
        return {
            mdiYoutube,
            isVideoLoaded: false,
            videoOptions: {
                // https://github.com/vimeo/player.js/#embed-options
                responsive: true,
                keyboard: false,
                title: false,
                playsinline: false,
            },
        }
    },
    computed: {
        displayedVideo() {
            return this.videos[0]
        },
        isHeader() {
            return this.type === "header"
        },
        isSeo() {
            return this.type === "seo"
        },
        isMobile() {
            return this.$breakpoint.smAndDown
        },
    },
}
