import { render, staticRenderFns } from "./RegionSeoContent.vue?vue&type=template&id=139e9c10&scoped=true&"
import script from "./RegionSeoContent.vue?vue&type=script&lang=js&"
export * from "./RegionSeoContent.vue?vue&type=script&lang=js&"
import style0 from "./RegionSeoContent.vue?vue&type=style&index=0&id=139e9c10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139e9c10",
  null
  
)

export default component.exports