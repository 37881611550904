
const debounce = require("lodash.debounce")

export default {
    name: "SearchResultList",
    components: {
        BusinessList: () => import("@/components/business/BusinessList"),
        PostList: () => import("@/components/post/PostList"),
        JobList: () => import("@/components/job/JobList"),
        SkeletonList: () => import("@/components/base/list/SkeletonList"),
    },
    props: {
        targetBlank: {
            type: Boolean,
            default: false,
        },
        limit: {
            default: null,
            type: Number,
        },
        showAllText: {
            default: "",
            type: String,
        },
        showAllRoute: {
            default: null,
            type: Object,
        },
        showMoreCard: {
            default: true,
            type: Boolean,
        },
        wrap: {
            default: false,
            type: Boolean,
        },
        resultListClass: {
            type: String,
        },
        searchCategory: {
            type: String,
        },
        noCaching: {
            type: [Boolean, Number],
            default: false,
        },
        virtual: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            debounceSearch: debounce(
                this.initSearch,
                this.noCaching > 1 ? this.noCaching : 1
            ),
        }
    },
    computed: {
        searchID() {
            return this.$store.getters["search/searchID"](this.searchCategory)
        },
        search() {
            return this.$store.getters["search/search"](this.searchID)
        },
        observe() {
            return this.search.maxPages > this.search.currentPage
        },
        dataAttribute() {
            switch (this.searchCategory) {
                case "Unternehmen":
                    this.checkBusinessResultLength({
                        businesses: this.search.data,
                    })
                    return {
                        items: this.search.data,
                    }
                case "Ausbildung":
                    return { items: this.search.data }
                case "Praktikum":
                    return { items: this.search.data }
                case "Neuigkeiten":
                case "BusinessNeuigkeiten":
                case "RegionNeuigkeiten":
                    return { items: this.search.data }
                default:
                    return []
            }
        },
    },
    watch: {
        searchID: function () {
            this.initSearch()
        },
    },
    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.initSearch()
            }
        },
        loadNext() {
            if (this.search.maxPages > this.search.currentPage) {
                this.initSearch()
            }
        },
        initSearch() {
            this.$store.dispatch("search/performSearch", {
                category: this.searchCategory,
            })
        },
        checkBusinessResultLength(result) {
            if (result.businesses) {
                if (result.businesses.length === 0) {
                    this.$emit("no-results")
                } else {
                    this.$emit("results")
                }
            }
        },
    },
}
